import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notification-home-preview',
  templateUrl: './notification-home-preview.component.html'
})
export class NotificationHomePreviewComponent implements OnInit {

  @Input() notificationDetails: any;
  @Input() createEditForm: any;
  @Output() handleCustomNotificationIconDisplayEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  handleCustomNotificationIconDisplay() {
    this.handleCustomNotificationIconDisplayEvent.emit();
  }

}
