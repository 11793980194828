import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MediaManagerService {

  constructor(private apiService: ApiService) { }

  getAvailableImages() {
    return this.apiService.getData(environment.mediaPrefix + '/v1/media/notification-images', 'standard');
  }

  uploadImage(image): Observable<any> {
    return this.apiService.postData(environment.mediaPrefix + '/v1/media/notification-images', image, 'standard');
  }
}
