import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isNumber } from 'lodash';
import { NotificationsService } from '../../../engagement/services/notifications.service';
import { NotificationBuilderService } from '../../services/notification-builder.service';

@Component({
  selector: 'app-notification-builder-edit',
  templateUrl: './notification-builder-edit.component.html'
})
export class NotificationBuilderEditComponent implements OnInit {

  @ViewChild('ctaProductsChips') ctaProductsChips: ElementRef<HTMLInputElement>;
  @Input() editedModule: any;
  @Input() createEditForm: any;
  @Input() notificationDataTypes: Array<any>;
  @Input() ctaChosenProducts: Array<any>;
  @Input() showButtonExternalLink: boolean;
  @Input() loadingAvailableProducts: boolean;
  @Input() loadingAvailableAlgorithms: boolean;
  @Input() loadingAvailableCTAProducts: boolean;
  @Input() ctaChipsInput: any;
  @Input() videoNodeID: any;
  @Input() carouselValidation: any;
  @Input() editedModuleIndex: number;

  @Input() filteredAlgorithms: Array<any>;
  @Input() filteredProducts: Array<any>;
  @Input() filteredCTAProducts: Array<any>;
  @Input() availableAlgorithms: Array<any>;
  @Input() availableCTAProducts: Array<any>;
  @Input() availableProducts: Array<any>;

  @Output() handleCTAChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeModuleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateCarouselEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateProductEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  availableButtonOptions: Array<any>;
  productSeparatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(public sanitizer: DomSanitizer,
              private notificationsService: NotificationsService,
              private notificationBuilderService: NotificationBuilderService) { }

  ngOnInit() {
    this.getNotificationButtonTypes();
  }

  getNotificationButtonTypes() {
    this.availableButtonOptions = this.notificationsService.getButtonOptions();
  }

  handleImageSelect(image) {
    this.editedModule.content = image;
  }

  addVideo(event) {
    const youTubeURL = 'https://www.youtube.com/embed/' + event;
    this.editedModule.tempData = {};
    this.editedModule.tempData.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(youTubeURL);
    this.editedModule.content = youTubeURL;
  }

  removeVideo() {
    this.editedModule.tempData = {};
    this.editedModule.content = this.notificationDataTypes[3].content;
  }

  handleCTAChange(event) {
    this.handleCTAChangeEvent.emit(event);
  }

  filterAlgorithms(searchTerm) {
    this.filteredAlgorithms = this.notificationBuilderService.filterModuleContent(searchTerm,
      this.filteredAlgorithms,
      this.availableAlgorithms,
      'algorithms');
  }

  filterCTAProducts(searchTerm) {
    this.filteredCTAProducts = this.notificationBuilderService.filterModuleContent(searchTerm,
      this.filteredCTAProducts,
      this.availableCTAProducts,
      'products');
  }

  filterModuleProducts(searchTerm) {
    this.filteredProducts = this.notificationBuilderService.filterModuleContent(searchTerm,
      this.filteredProducts,
      this.availableProducts,
      'products');
  }

  removeProductFromChips(product) {
    const index = this.editedModule.content.product_ids.indexOf(product.id);
    const chosenIndex = this.ctaChosenProducts.indexOf(product);
    if (chosenIndex >= 0) {
      this.ctaChosenProducts.splice(chosenIndex, 1);
    }
    if (index >= 0) {
      this.editedModule.content.product_ids.splice(index, 1);
    }
  }

  addCTAProduct(product) {
    // Reset input
    this.ctaChipsInput = '';
    this.ctaProductsChips.nativeElement.value = '';
    // If product doesn't exist in arrays then push
    if (this.ctaChosenProducts.findIndex(i => i.id === product.option.value.id) === -1) {
      this.ctaChosenProducts.push(product.option.value);
    }
    if (this.editedModule.content.product_ids.findIndex(i => i === product.option.value.id) === -1) {
      this.editedModule.content.product_ids.push(product.option.value.id);
    }
  }

  addModuleProduct(product) {
    this.editedModule.content = {...product.option.value};
    this.validateProductEvent.emit(isNumber(this.editedModule.content?.id));
  }

  switchCarouselOption(index: number) {
    this.editedModule.tempData.selectedCarouselOption = this.editedModule.content.find((x, i) => i === index);
  }

  addCarouselSlide() {
    this.editedModule = this.notificationBuilderService.addCarouselSlide(this.editedModule);
    this.validateCarouselEvent.emit();
  }

  handleCarouselImageSelect(event) {
    this.editedModule.tempData.selectedCarouselOption.image_url = event;
    this.validateCarouselEvent.emit();
  }

  onMessage() {
    this.validateCarouselEvent.emit();
  }

  removeSlide(event) {
    this.editedModule = this.notificationBuilderService.removeCarouselSlide(this.editedModule, event);
    this.validateCarouselEvent.emit();
  }

  removeModule(event) {
    this.removeModuleEvent.emit(event);
  }

}
