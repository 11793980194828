<section class="sidebar-holder">
  <div class="main-logo">
    <img src="./assets/logos/vet-ai-logo.svg" />
    <span class="logo-title">CRM</span>
  </div>
  <ul class="menu">
    <li *ngFor="let route of routes" class="list-unstyled">
      <span class="section uppercase-text">{{route.label}}</span>
      <ul class="sub-menu list-unstyled">
        <li *ngFor="let page of route.pageLinks" class="link" [class.isActive]="page.isActive">
          <a [routerLink]="[page.url]">{{page.label}}</a>
        </li>
      </ul>
    </li>
    <li class="logout-btn" (click)="logout()">
      <a>Logout</a>
    </li>
  </ul>
</section>
