<article class="home-screen-card-wrapper" *ngIf="notificationDetails">
  <div class="home-screen-card" [class.single-card]="createEditForm?.controls?.has_custom_icon?.value">
    <div
      class="home-screen-card-image-holder"
      [class.is-editable]="createEditForm?.controls?.has_custom_icon?.value"
      (click)="handleCustomNotificationIconDisplay()">
      <img
        alt="main icon"
        class="main-icon"
        *ngIf="!notificationDetails.notification_custom_icon || !createEditForm?.controls?.has_custom_icon?.value"
        src="assets/svgs/health_plan_home_screen_card_1.svg" />
      <img
        alt="main icon"
        class="main-icon"
        *ngIf="notificationDetails.notification_custom_icon && createEditForm?.controls?.has_custom_icon?.value"
        [src]="notificationDetails.notification_custom_icon" />
    </div>
    <section>
      <h4 class="title">{{createEditForm?.controls?.title?.value}}</h4>
      <p class="body">{{createEditForm?.controls?.body?.value}}</p>
      <mat-icon>chevron_right</mat-icon>
    </section>
  </div>
  <div class="home-screen-card" *ngIf="!createEditForm?.controls?.has_custom_icon?.value">
    <div class="pet-home-card-image">
      <img alt="pet icon" class="pet-icon" src="assets/svgs/health_plan_home_screen_card_2.svg" />
      <img alt="user profile image" class="profile" src="assets/placeholders/health_plan_home_screen_profile.jpg" />
    </div>
    <section>
      <h4 class="title">{{createEditForm?.controls?.title?.value}}</h4>
      <p class="body">{{createEditForm?.controls?.body?.value}}</p>
      <mat-icon>chevron_right</mat-icon>
    </section>
  </div>
</article>
