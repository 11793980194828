import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainSidebarComponent } from './components/main-sidebar/main-sidebar.component';
import { SetStatusComponent } from './components/set-status/set-status.component';
import { ImageManagerComponent } from './components/image-manager/image-manager.component';
import { NotificationBuilderPreviewComponent } from './components/notification-builder-preview/notification-builder-preview.component';
import { NotificationBuilderOptionsComponent } from './components/notification-builder-options/notification-builder-options.component';
import { NotificationBuilderEditComponent } from './components/notification-builder-edit/notification-builder-edit.component';
import { NotificationHomePreviewComponent } from './components/notification-home-preview/notification-home-preview.component';
import { JoiiCurrencyPipe } from './pipes/joii-currency.pipe';
import { PluralPipe } from './pipes/plural.pipe';
import { TypeOfPipe } from './pipes/type-of.pipe';
import { ConditionQuestionComponent } from './components/condition-question/condition-question.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    MainSidebarComponent,
    SetStatusComponent,
    ImageManagerComponent,
    JoiiCurrencyPipe,
    PluralPipe,
    TypeOfPipe,
    NotificationBuilderPreviewComponent,
    NotificationBuilderOptionsComponent,
    NotificationBuilderEditComponent,
    NotificationHomePreviewComponent,
    ConditionQuestionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
  ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        MainSidebarComponent,
        SetStatusComponent,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatDialogModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatCheckboxModule,
        JoiiCurrencyPipe,
        PluralPipe,
        ImageManagerComponent,
        TypeOfPipe,
        NotificationBuilderOptionsComponent,
        NotificationBuilderPreviewComponent,
        NotificationBuilderEditComponent,
        NotificationHomePreviewComponent,
        ConditionQuestionComponent
    ]
})
export class SharedModule { }
