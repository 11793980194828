<form [formGroup]="formGroup" *ngIf="formGroup">
<div class="condition-container">
<mat-form-field class="condition-select">
  <mat-select formControlName="condition_property" >
    <mat-option
      *ngFor="let availableProperties of availableConditions"
      [value]="availableProperties.property">
      {{availableProperties.property}}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field class="condition-operator">
  <mat-select formControlName="operation">
    <mat-option
      *ngFor="let availableOperations of condition?.operations"
      [value]="availableOperations">
      {{availableOperations}}
    </mat-option>
  </mat-select>
</mat-form-field>
  <ng-container [ngSwitch]="condition?.value_type">
    <!--
      Any structural directives on inputs within a form field will cause tests to break.
      https://github.com/angular/components/issues/7898
    -->
    <mat-form-field *ngSwitchCase="'text'" class="condition-selection">
      <input
        matInput
        formControlName="condition_value"
        *ngSwitchCase="'text'"
        type="text"
        autocomplete="off"
      />
    </mat-form-field>
    <mat-form-field *ngSwitchCase="'number'" class="condition-selection">
      <input
        matInput
        *ngSwitchCase="'number'"
        type="number"
        formControlName="condition_value"
        autocomplete="off"/>
    </mat-form-field>
    <ng-container *ngSwitchCase="'select'">
      <mat-form-field class="condition-select">
        <div class="select-container">
          <mat-select
            formControlName="condition_value">
            <mat-option *ngFor="let option of condition.value_options" [value]="option">{{option}}</mat-option>
          </mat-select>
          <div class="mat-spinner">
            <mat-spinner [diameter]="20" *ngIf="condition?.isLoading"></mat-spinner>
          </div>
        </div>
      </mat-form-field>
    </ng-container>
    <mat-form-field *ngSwitchCase="'boolean'" class="condition-selection">
      <mat-select
        formControlName="condition_value">
        <mat-option *ngFor="let option of ['true', 'false']" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <mat-error *ngIf="!formGroup.valid && formGroup.touched">{{name}} is incomplete</mat-error>
</div>
</form>
