import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  AfterViewChecked,
  ViewChildren,
  QueryList,
  ElementRef,
  HostListener, ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-notification-builder-preview',
  templateUrl: './notification-builder-preview.component.html'
})
export class NotificationBuilderPreviewComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChildren('notificationBuilderPreview') notificationBuilderPreview: QueryList<ElementRef>;
  @Input() notificationDataTypes: Array<any>;
  @Input() notificationContent: Array<any>;
  @Input() createEditForm: any;
  @Output() dropModuleOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() editModuleOut: EventEmitter<any> = new EventEmitter<any>();

  notificationBuilderWidth: number;
  notificationBuilderPadding: number;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.notificationBuilderPadding = 40;
  }

  ngAfterViewInit() {
    if (this.notificationBuilderPreview.first) {
      this.notificationBuilderWidth = this.notificationBuilderPreview.first.nativeElement.offsetWidth - this.notificationBuilderPadding;
    }
  }

  ngAfterViewChecked() {
    // Forces additional change detection check for after view init setter.
    // After content init workaround is not available due to ViewChildren not existing in the lifecycle hook.
    // @ToDo: Rethink this later.
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.notificationBuilderPreview &&
      this.notificationBuilderPreview.first &&
      this.notificationBuilderPreview.first.nativeElement) {
      this.notificationBuilderWidth = this.notificationBuilderPreview.first.nativeElement.offsetWidth - this.notificationBuilderPadding;
    }
  }

  dropModule(event) {
    this.dropModuleOut.emit(event);
  }

  editModule(module, index) {
    const emitPackage = {
      module,
      index
    };
    this.editModuleOut.emit(emitPackage);
  }

}
