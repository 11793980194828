import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }
  /**
   * getData function - Calls http.get (with endpoint param) and gets appropriate headers from getHeaders call
   * @param: (String) endpoint
   */
  getData(endpoint, isSimple?): Observable<any> {
    const headers: any = this.getHeaders(isSimple);
    return this.http.get(endpoint, headers);
  }
  /**
   * postData function - Calls http.post (with endpoint and data params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (Object) data
   * @param: (String) isSimple
   */
  postData<T = any>(endpoint, data, isSimple?) {
    const headers: any = this.getHeaders(isSimple);
    return this.http.post(endpoint, data, headers) as unknown as Observable<T>;
  }
  /**
   * putData function - Calls http.put (with endpoint and data params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (Object) data
   * @param: (String) isSimple
   */
  putData(endpoint, data, isSimple?) {
    const headers: any = this.getHeaders(isSimple);
    return this.http.put(endpoint, data, headers);
  }
  /**
   * patchData function - Calls http.patch (with endpoint and data params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (Object) data
   * @param: (String) isSimple
   */
  patchData<T = any>(endpoint, data, isSimple?) {
    const headers: any = this.getHeaders(isSimple);
    return this.http.patch(endpoint, data, headers) as unknown as Observable<T>;
  }
  /**
   * deleteData function - Calls http.delete (with endpoint params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (String) isSimple
   */
  deleteData(endpoint, isSimple?) {
    const headers: any = this.getHeaders(isSimple);
    return this.http.delete(endpoint, headers);
  }
  /**
   * getHeaders function - Creates and returns new HTTP Headers based on type param
   * @param: (String) type
   */
  getHeaders(type) {
    let headers;
    switch (type) {
      case 'standard':
        headers = {
          headers: {
            Authorization: 'Bearer ' + this.getUserToken()
          }
        };
        break;
      case 'simple':
      default:
        break;
    }
    return headers;
  }
  /**
   *
   */
  getUserToken() {
    return localStorage.getItem('userToken');
  }
}
