import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {

  transform(value: any, condition: boolean = true): any {
    return condition ? `${value}s` : value;
  }

}
