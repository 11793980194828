<section
  [attr.class]="'status-holder status-' + currentStatus"
  [class.blocked]="allowedOperations?.length === 0"
  (click)="displayStatusToggle($event)">
  <article class="status-text">{{currentStatus}}</article>
</section>
<section *ngIf="showAvailableStatus && allowedOperations?.length > 0" class="status-toggle">
  <div
    *ngFor="let status of allowedOperations"
    [attr.class]="'status status-' + status"
    (click)="updateStatusNow($event, status)">
    <span class="status-text">{{status}}</span>
  </div>
</section>
