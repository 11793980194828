import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { StatusConfigInterface } from '../../interfaces/status-config.interface';
import { StatusEnum } from '../../enums/status.enum';

@Component({
  selector: 'app-set-status',
  templateUrl: './set-status.component.html'
})
export class SetStatusComponent implements OnInit, OnChanges {
  @Input() statusConfig: Array<StatusConfigInterface>;
  @Input() currentStatus: StatusEnum;
  @Input() objectType: string;
  @Input() objectID: string;
  @Output() updateStatus: EventEmitter<any> = new EventEmitter<any>();
  allowedOperations: Array<StatusEnum>;
  showAvailableStatus: boolean;

  constructor() { }

  ngOnInit() {
    this.setAllowedOperationsFromConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.currentStatus && !changes.currentStatus.isFirstChange()) {
      this.setAllowedOperationsFromConfig();
    }
  }

  setAllowedOperationsFromConfig() {
    if (!this.statusConfig || this.statusConfig.length === 0) {
      return;
    }
    const matched = this.statusConfig.find((config) => {
      return config.status === this.currentStatus;
    });
    if (!matched || !matched.hasOwnProperty('allowedStatusChanges')) {
      this.allowedOperations = [];
    } else {
      this.allowedOperations = matched.allowedStatusChanges || [];
    }
  }

  displayStatusToggle(event) {
    event.stopPropagation();
    this.showAvailableStatus = !this.showAvailableStatus;
  }

  updateStatusNow(event, status) {
    event.stopPropagation();
    this.showAvailableStatus = false;
    this.updateStatus.emit(status);
  }

}
