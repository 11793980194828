import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joiiCurrency'
})
export class JoiiCurrencyPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    return '£' + (value / 100).toFixed(2);
  }

}
