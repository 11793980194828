import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-main-sidebar',
  templateUrl: './main-sidebar.component.html'
})
export class MainSidebarComponent implements OnInit, OnDestroy {

  routes: Array<any>;
  private subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.createRoutes();
    this.checkRoutes();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  checkRoutes() {
    let parentRoute;
    let path;
    // Get Parent Route
    this.subscriptions.add(this.route.parent.url.subscribe(parent => {
      parentRoute = parent[0].path;
      if (parentRoute && path) {
        this.checkIfActive(parentRoute, path);
      }
    }));
    // Get Child Route
    this.subscriptions.add(this.route.url.subscribe(url => {
      path = url[0].path;
      if (parentRoute && path) {
        this.checkIfActive(parentRoute, path);
      }
    }));
  }

  checkIfActive(parentRoute, path) {
    const pathCheck = '/' + parentRoute + '/' + path;
    this.routes.forEach(route => {
      route.pageLinks.forEach(link => {
        if (link.url === pathCheck || link.url.slice(0, -1) === pathCheck) {
          link.isActive = true;
        }
      });
    });
  }

  createRoutes() {
    this.routes = [
      {
        id: 1,
        label: 'Segmentation',
        pageLinks: [
          {
            id: 1,
            label: 'Pet Segments',
            url: '/segmentation/pets',
            isActive: false
          }
        ]
      },
      {
        id: 2,
        label: 'Engagement',
        pageLinks: [
          {
            id: 1,
            label: 'Health Plans',
            url: '/engagement/health-plans',
            isActive: false
          },
          {
            id: 2,
            label: 'Links Manager',
            url: '/engagement/links-manager',
            isActive: false
          },
          {
            id: 3,
            label: 'User Behaviour',
            url: '/engagement/user-behaviour',
            isActive: false
          },
          {
            id: 4,
            label: 'Pet Behaviour',
            url: '/engagement/pet-behaviour',
            isActive: false
          }
        ]
      }
    ];
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userToken');
    this.router.navigate(['/auth/login']);
  }

}
