import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConditionInterface } from '../../interfaces/condition.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-condition-question',
  templateUrl: './condition-question.component.html'
})
export class ConditionQuestionComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() availableConditions: Array<ConditionInterface>;
  @Input() condition: ConditionInterface;
  @Input() errors: Array<string>;
  @Output() conditionChange: EventEmitter<ConditionInterface> = new EventEmitter<ConditionInterface>();

  @Input() formGroup: FormGroup;

  constructor() { }

  ngOnInit() {
    this.setupQuestionForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.condition && !changes.condition.isFirstChange()) {
      this.updateQuestion();
    }
  }

  updateQuestion() {
    if (this.formGroup && this.formGroup.controls.hasOwnProperty('condition_property')) {
      this.formGroup.patchValue({
        condition_property: this.condition.property,
        operation: this.condition.operation,
        condition_value: this.condition.value
      });
      if (this.condition.operation) {
        this.onPropertyChanged();
      }
    } else {
      this.setupQuestionForm();
    }
  }

  onPropertyChanged() {
    const matched = this.availableConditions?.find((x) => {
      return x.property === this.condition.property;
    });
    this.condition = {...this.condition, ...matched};
  }

  setupQuestionForm() {
    if (!this.formGroup) {
      this.formGroup = new FormGroup({
        condition_property: new FormControl(this.condition?.property, Validators.required),
        operation: new FormControl(this.condition?.operation, Validators.required),
        condition_value: new FormControl(this.condition?.value, Validators.required)
      });

    } else if (this.formGroup) {
      this.formGroup.reset();
      this.formGroup.addControl('condition_property', new FormControl(this.condition?.property, Validators.required));
      this.formGroup.addControl('operation', new FormControl(this.condition?.operation, Validators.required));
      this.formGroup.addControl('condition_value', new FormControl(this.condition?.value, Validators.required));
      if (this.condition.operation) {
        this.onPropertyChanged();
      }
    }

    this.formGroup.controls.condition_property.valueChanges.subscribe((change) => {
      if (change !== this.condition.property) {
        this.condition.property = change;
        this.onPropertyChanged();
        this.conditionChange.emit(this.condition);
      }
    });

    this.formGroup.controls.operation.valueChanges.subscribe((change) => {
      if (change !== this.condition.operation) {
        this.condition.operation = change;
        this.conditionChange.emit(this.condition);
      }
    });

    this.formGroup.controls.condition_value.valueChanges.subscribe((change) => {
      if (change !== this.condition.value) {
        this.condition.value = change;
        this.conditionChange.emit(this.condition);
      }
    });
  }

}
