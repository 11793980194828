import { Injectable } from '@angular/core';
import { isNumber } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NotificationBuilderService {

  constructor() { }

  cleanTemplate(template) {
    template.forEach(item => {
      if (item.type === 'product') {
        item.content = item.content.id;
        if (typeof item.custom_summary !== 'string' || !item.tempData.hasProductTextOverride) {
          delete item.custom_summary;
        }
      }
      if (item.type === 'carousel') {
        item.content.forEach(slide => {
          delete slide.index;
        });
      }
      delete item.tempData;
    });
    return template;
  }

  addCarouselSlide(module) {
    let newSlide = {image_url: '', message: ''} as any;
    if (typeof module.content === 'string') {
      module.content = [];
    }
    newSlide = {...newSlide, index: module.content.length};
    module.content.push(newSlide);
    module.tempData.selectedCarouselOption = newSlide;
    return module;
  }

  removeCarouselSlide(module, index) {
    module.content.splice(index, 1);
    // Update the order or the slides.
    module.content = module.content.map((x, i) => {
      x.index = i;
      return x;
    });
    // Update or clear the selected slide.
    module.tempData.selectedCarouselOption = this.getSelectedSlideAfterRemoval(module.content, index);
    if (module.content.length === 0) {
      module.content = './assets/placeholders/carousel-placeholder.png';
    }
    return module;
  }

  getSelectedSlideAfterRemoval(slides: any[], prevIndex): any {
    // No slides available
    if (!slides || slides.length === 0) {
      // clear selection.
      return undefined;
    }
    // removed slide was the first slide
    if (prevIndex === 0 && slides.length > 0) {
      // select the next slide in the slider.
      return slides[0];
    }
    // removed slide had previous slides in the slider or was the last slide
    // Select the previous slide in the slider.
    return slides[prevIndex - 1];
  }

  validateCarousel(content): boolean {
    let carouselValid;
    const carousel = content.find((x) => x.type === 'carousel');
    if (carousel && carousel.hasOwnProperty('content') && typeof carousel.content === 'string') {
      carouselValid = false;
      return carouselValid;
    }
    if (carousel && carousel.hasOwnProperty('content') && carousel.content) {
      // Carousel element been added to notification.
      let carouselDetailsValid = true;
      // carousel must have at least 1 slide.
      carouselDetailsValid = carouselDetailsValid && carousel.content.length > 0;
      // all slides must have both an image and a message.
      carousel.content.forEach(slide => {
        carouselDetailsValid = carouselDetailsValid && (typeof slide.message === 'string' && slide.message.length > 0) &&
          (typeof slide.image_url === 'string' && slide.image_url.length > 0);
      });
      carouselValid = carouselDetailsValid;
    } else {
      // Allow save when no carousel is added to the notification.
      carouselValid = true;
    }
    return carouselValid;
  }

  validateProduct(content: any[]) {
    const productContent = content.find(x => x.type === 'product');
    if (productContent) {
      return isNumber(productContent.content) ? true : isNumber(productContent.content.id);
    }
    return true;
  }

  validateContent(content: any[]) {
    const isValidTotalItems = content?.length >= 3;
    const isValidProduct = this.validateProduct(content);
    const isValidCarousel = this.validateCarousel(content);
    return isValidTotalItems && isValidCarousel && isValidProduct;
  }

  filterModuleContent(searchTerm, module, source, moduleType) {
    const regExProd = new RegExp('^\\d+$');
    const termCheck = regExProd.test(searchTerm);
    let moduleTerm;
    let termDetails;
    let searchCheck;
    switch (moduleType) {
      case 'algorithms':
        moduleTerm = 'title';
        break;
      case 'products':
      default:
        moduleTerm = 'name';
        break;
    }
    if (!searchTerm) {
      module = source;
      return module;
    }
    module = source.filter(option => {
      if (termCheck) {
        termDetails = searchTerm.toString();
        searchCheck = option.id.toString();
      } else {
        termDetails = searchTerm.toLowerCase();
        searchCheck = option[moduleTerm].toLowerCase();
      }
      return searchCheck.includes(termDetails);
    });
    return module;
  }

  getAllCallToActions(template) {
    const callToActions = [];
    let callToActionsString = '';
    template.forEach(item => {
      if (item.type === 'call-to-action') {
        callToActions.push(item.content.type);
      }
    });
    callToActions.forEach((cta, index) => {
      if (index !== callToActions.length - 1) {
        callToActionsString += cta + ', ';
      } else {
        callToActionsString += cta;
      }
    });
    return callToActionsString;
  }
}
