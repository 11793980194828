<div>
  <h2>Edit {{editedModule.type}}</h2>
  <div [ngSwitch]="editedModule.type">
    <!-- Title Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[0].type">
      <mat-form-field>
        <input
          matInput
          [disabled]="createEditForm && createEditForm.disabled"
          [(ngModel)]="editedModule.content" />
      </mat-form-field>
    </article>
    <!-- Title Edit Ends -->
    <!-- Body Text Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[1].type">
      <mat-form-field>
        <textarea
          matInput
          cdkTextareaAutosize
          [disabled]="createEditForm && createEditForm.disabled"
          [(ngModel)]="editedModule.content"></textarea>
      </mat-form-field>
    </article>
    <!-- Body Text Edit Ends -->
    <!-- Image Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[2].type">
      <app-image-manager
        [chosenImage]="editedModule.content"
        (updateImage)="handleImageSelect($event)"></app-image-manager>
    </article>
    <!-- Image Edit Ends -->
    <!-- Video Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[3].type">
      <div *ngIf="editedModule.content === notificationDataTypes[3].content">
        <img [src]="editedModule.content" />
        <mat-form-field>
          <input
            matInput
            type="text"
            placeholder="YouTube ID"
            [disabled]="createEditForm && createEditForm.disabled"
            [(ngModel)]="videoNodeID" />
        </mat-form-field>
        <button
          mat-flat-button
          color="primary"
          class="full-width"
          [disabled]="createEditForm && createEditForm.disabled"
          (click)="addVideo(videoNodeID)">Add video</button>
      </div>
      <div *ngIf="editedModule.content !== notificationDataTypes[3].content">
        <iframe
          *ngIf="editedModule.tempData.sanitizedURL"
          [src]="editedModule.tempData.sanitizedURL"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        <button
          mat-flat-button
          color="primary"
          class="full-width"
          [disabled]="createEditForm && createEditForm.disabled"
          (click)="removeVideo()">Remove video</button>
      </div>
    </article>
    <!-- Video Edit Ends -->
    <!-- CTA Button Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[4].type">
      <mat-form-field>
        <input matInput [disabled]="createEditForm && createEditForm.disabled" [(ngModel)]="editedModule.content.label" />
      </mat-form-field>
      <mat-radio-group
        [(ngModel)]="editedModule.content.type"
        [disabled]="createEditForm && createEditForm.disabled"
        (ngModelChange)="handleCTAChange($event)">
        <mat-radio-button
          *ngFor="let option of availableButtonOptions"
          [value]="option.value"
          [class.is-deep]="option.value === 'symptom-checker' ||
                option.value === 'external-link' ||
                option.value === 'buy-products'"
          color="primary">
          {{option.label}}
          <!-- Select Algorithm Autocomplete Starts -->
          <div *ngIf="loadingAvailableAlgorithms && option.value === 'symptom-checker'">
            <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
          </div>
          <div *ngIf="!loadingAvailableAlgorithms && filteredAlgorithms && option.value === 'symptom-checker'">
            <mat-form-field>
              <input
                matInput
                [disabled]="createEditForm && createEditForm.disabled"
                [(ngModel)]="editedModule.content.triage_id"
                [matAutocomplete]="algorithms"
                (keyup)="filterAlgorithms(editedModule.content.triage_id)" />
            </mat-form-field>
            <mat-autocomplete #algorithms="matAutocomplete">
              <mat-option *ngFor="let option of filteredAlgorithms" [value]="option.id">
                ID: {{option.id}} Title: {{option.title}}
              </mat-option>
            </mat-autocomplete>
          </div>
          <!-- Select Algorithm Autocomplete Ends -->
          <!-- Select Product Autocomplete Starts -->
          <div *ngIf="loadingAvailableCTAProducts && option.value === 'buy-products'">
            <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
          </div>
          <div *ngIf="!loadingAvailableCTAProducts && filteredCTAProducts && option.value === 'buy-products'">
            <mat-chip-list #chipList aria-label="Product selection">
              <mat-chip
                *ngFor="let product of ctaChosenProducts"
                [disabled]="createEditForm && createEditForm.disabled"
                [selectable]="true"
                [removable]="true"
                (removed)="removeProductFromChips(product)">
                ID: {{product.id}} {{product.name | slice: 0:15}}...
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <mat-form-field>
                <input
                  matInput
                  #ctaProductsChips
                  [disabled]="createEditForm && createEditForm.disabled"
                  [(ngModel)]="ctaChipsInput"
                  [matAutocomplete]="products"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="productSeparatorKeysCodes"
                  (keyup)="filterCTAProducts(ctaChipsInput)"
                  (matChipInputTokenEnd)="filterCTAProducts(ctaChipsInput)" />
              </mat-form-field>
            </mat-chip-list>
            <mat-autocomplete #products="matAutocomplete" (optionSelected)="addCTAProduct($event)">
              <mat-option *ngFor="let option of filteredCTAProducts" [value]="option">
                ID: {{option.id}} Name: {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </div>
          <!-- Select Product Autocomplete Ends -->
          <!-- External Link Input Starts -->
          <div *ngIf="option.value === 'external-link' && showButtonExternalLink">
            <mat-form-field>
              <input
                matInput
                [disabled]="createEditForm && createEditForm.disabled"
                [(ngModel)]="editedModule.content.link.url" />
            </mat-form-field>
            <label>Webview:</label>
            <mat-form-field>
              <mat-select [(ngModel)]="editedModule.content.link.web_view">
                <mat-option [value]="true">True</mat-option>
                <mat-option [value]="false">False</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- External Link Input Ends -->
        </mat-radio-button>
      </mat-radio-group>
    </article>
    <!-- CTA Button Edit Ends -->
    <!-- Product Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[5].type">
      <div *ngIf="loadingAvailableProducts">
        <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
      </div>
      <div *ngIf="!loadingAvailableProducts && filteredProducts">
        <mat-form-field class="product-selection-container">
          <mat-label>PRODUCT</mat-label>
          <input
            matInput
            [disabled]="createEditForm && createEditForm.disabled"
            [(ngModel)]="editedModule.content.id"
            [matAutocomplete]="moduleProducts"
            (keyup)="filterModuleProducts(editedModule.content.id)" />
        </mat-form-field>
        <mat-autocomplete
          #moduleProducts="matAutocomplete"
          (optionSelected)="addModuleProduct($event)">
          <mat-option *ngFor="let option of filteredProducts" [value]="option">
            ID: {{option.id}} Title: {{option.name}}
          </mat-option>
        </mat-autocomplete>
        <div class="override-product-radio-container">
          <label>Override product description?</label>
          <mat-radio-group [(ngModel)]="editedModule.tempData.hasProductTextOverride">
            <mat-radio-button
              [value]="true"
              color="primary">
              Yes
            </mat-radio-button>
            <mat-radio-button
              [value]="false"
              color="primary">
              No
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field *ngIf="editedModule?.tempData?.hasProductTextOverride">
          <mat-label>OVERRIDE TEXT</mat-label>
          <textarea
            matInput
            maxlength="130"
            [disabled]="createEditForm && createEditForm.disabled"
            [(ngModel)]="editedModule.custom_summary"
            cdkTextareaAutosize></textarea>
        </mat-form-field>
      </div>
    </article>
    <!-- Product Edit Ends -->
    <!-- Carousel Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[7].type" class="carousel-editing">
      <div class="carousel-slide-items">
        <ng-container *ngIf="(editedModule.content | typeof) !== 'string' && this.editedModule.content?.length > 0">
          <div
            class="slide-item"
            [class.selected]="editedModule.tempData.selectedCarouselOption.index === i"
            [class.invalid]="!item.message || item.message.length === 0 || !item?.image_url || item.image_url?.length === 0"
            *ngFor="let item of editedModule?.content; let i = index;"
            (click)="switchCarouselOption(i)">{{i + 1}}</div>
        </ng-container>
        <mat-icon
          *ngIf="editedModule?.content?.length < 6 || (editedModule?.content | typeof) === 'string'"
          (click)="addCarouselSlide()">add</mat-icon>
      </div>
      <p
        class="mat-error"
        *ngIf="(editedModule.content | typeof) !== 'string' && this.editedModule.content?.length > 0 &&
        (!editedModule?.tempData?.selectedCarouselOption?.message || editedModule?.tempData?.selectedCarouselOption?.message?.length === 0 ||
        !editedModule?.tempData?.selectedCarouselOption?.image_url || editedModule.tempData.selectedCarouselOption.image_url?.length === 0)">
        Slide requires a image and a message.
      </p>
      <app-image-manager
        *ngIf="editedModule?.tempData?.selectedCarouselOption"
        [validation]="carouselValidation"
        [chosenImage]="editedModule.tempData.selectedCarouselOption.image_url"
        (updateImage)="handleCarouselImageSelect($event)"></app-image-manager>
      <mat-form-field *ngIf="editedModule?.tempData?.selectedCarouselOption">
        <mat-label>TEXT</mat-label>
        <textarea
          matInput
          [disabled]="createEditForm && createEditForm.disabled"
          cdkTextareaAutosize
          [(ngModel)]="editedModule.tempData.selectedCarouselOption.message"
          (ngModelChange)="onMessage()"></textarea>
      </mat-form-field>
      <button
        mat-stroked-button
        color="primary"
        *ngIf="(editedModule.content | typeof) !== 'string' && editedModule?.content?.length > 0"
        [disabled]="createEditForm && createEditForm.disabled"
        (click)="removeSlide(editedModule.tempData.selectedCarouselOption.index)">Remove carousel slide</button>
    </article>
    <!-- Carousel Edit Ends -->
  </div>
  <button
    mat-stroked-button
    color="primary"
    [disabled]="createEditForm && createEditForm.disabled"
    (click)="removeModule(editedModuleIndex)">Remove element</button>
</div>
