import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private snackBar: MatSnackBar) { }

  showAlert(message, type) {
    const alertConfig = {
      duration: 5000,
      panelClass: type
    };
    this.snackBar.open(message, '', alertConfig);
  }
}
