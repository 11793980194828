import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkUser();
  }

  private checkUser(): boolean {
    let guardCheck = false;
    const tokenLocalStorageCheck = localStorage.getItem('userToken');
    const userLocalStorageCheck = localStorage.getItem('currentUser');
    if (tokenLocalStorageCheck && userLocalStorageCheck) {
      guardCheck = true;
    }
    if (!guardCheck) {
      this.router.navigate(['/auth/login']);
    }
    return guardCheck;
  }
}
