import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private apiService: ApiService) { }

  getNotifications(): Observable<any> {
    return this.apiService.getData(environment.apiPrefix + '/v1/custom-notifications', 'standard');
  }

  deleteNotification(id): Observable<any> {
    return this.apiService.putData(environment.apiPrefix + '/v1/custom-notifications/' + id, {}, 'standard');
  }

  createNotification(notification): Observable<any> {
    return this.apiService.postData(environment.apiPrefix + '/v1/custom-notifications', notification, 'standard');
  }

  updateNotification(id, notification): Observable<any> {
    return this.apiService.postData(environment.apiPrefix + '/v1/custom-notifications/' + id, notification, 'standard');
  }

  getNotificationDataTypes() {
    return [
      {
        label: 'Title',
        type: 'title',
        content: 'Placeholder title'
      },
      {
        label: 'Body text',
        type: 'text',
        content: 'Placeholder body text'
      },
      {
        label: 'Image',
        type: 'image',
        content: './assets/placeholders/image-placeholder.png'
      },
      {
        label: 'Video',
        type: 'video',
        content: './assets/placeholders/video-placeholder.png'
      },
      {
        label: 'Button',
        type: 'call-to-action',
        content: {
          label: 'Placeholder button text',
          type: 'nurse-consultation',
        }
      },
      {
        label: 'Product',
        type: 'product',
        content: {
          id: null,
          name: 'Placeholder product',
          description: 'Some placeholder product description',
          images: [
            {
              image_url: './assets/placeholders/image-placeholder.png'
            }
          ],
          price: {
            price: 0
          }
        },
        custom_summary: null
      },
      {
        label: 'Delimiter',
        type: 'delimiter',
        content: ''
      },
      {
        label: 'Carousel',
        type: 'carousel',
        content: './assets/placeholders/carousel-placeholder.png'
      },
    ];
  }

  getButtonOptions() {
    return [
      {
        label: 'Nurse consultation',
        value: 'nurse-consultation'
      },
      {
        label: 'Free Nurse consultation',
        value: 'free-nurse-consultation'
      },
      {
        label: 'Vet consultation',
        value: 'vet-consultation'
      },
      {
        label: 'Free Vet consultation',
        value: 'free-vet-consultation'
      },
      {
        label: 'Symptom checker',
        value: 'symptom-checker'
      },
      {
        label: 'Buy products',
        value: 'buy-products'
      },
      {
        label: 'External link',
        value: 'external-link'
      },
      {
        label: 'Dismiss',
        value: 'dismiss'
      }
    ];
  }

  // Test notifications
  createTestNotification(notificationDetails: any, content: any, isHealthPlan = false): Observable<any> {
    const defaultNotificationProps = {
      access: 'pms',
      screen: 'custom',
      type: isHealthPlan ? 'health-plan' : content !== null ? 'reminder' : 'info'
    };

    return this.apiService.postData(
      `${environment.apiPrefix}/v1/custom-notifications`,
      {
        ...defaultNotificationProps,
        title: notificationDetails.title,
        body: notificationDetails.body,
        template: content
      },
      'standard'
      );
  }

  sendTestNotification(notificationId: number, petId: number, userId: number, healthPlanId: number, trigger?: string): Observable<any> {
    return this.apiService.postData(
      `${environment.apiPrefix}/v1/custom-notifications/${notificationId}/send?preview=true`,
      [{
        user_id: userId,
        pet_id: petId,
        notification_id: notificationId,
        sent: new Date().toISOString(),
        preview_data: {
          source_id: healthPlanId,
          source_type: healthPlanId !== null ? 'health_plan' : 'event',
          action_code: trigger
        },
      }],
      'standard'
    );
  }
}
